//@TODO separate custom code from libs in app.js build
$(document).ready(function () {

  $(document).foundation();

  // search functionality with overlay appearance.
  function activateSearchButton() {
    $('.search').click(function () {
      $(".search-overlay").slideToggle("fast");

      // Set focus to search input.
      $(".search-input").focus();
    });
    $(".ss-SearchOverlayClose").click(function () {
      $(".search-overlay").slideToggle("fast");
    });
  }

  activateSearchButton();

  function resizeSidebar() {
    $(".sidebar-filler, .sidebar:not(.sidebar-filler)").css({'height': ''});
    if (window.matchMedia('(min-width: 40em)').matches) {
      $(".sidebar-filler, .sidebar:not(.sidebar-filler)").css({'height': Math.max.apply(Math,
          [
            ($(".sidebar:not(.sidebar-filler)").length > 0 ? $(".sidebar:not(.sidebar-filler)").get(0).offsetHeight : 0),
            ($(".sidebar-filler").length > 0 ? $(".sidebar-extended").get(0).offsetHeight : 0),
            ($("article .row .columns:not(.sidebar)").get(0).offsetHeight),
            (document.documentElement.clientHeight - $("#top").get(0).offsetHeight - $("#bottom").get(0).offsetHeight - $("footer").get(0).offsetHeight - $("header").get(0).offsetHeight),
            (window.innerHeight - $("#top").get(0).offsetHeight - $("#bottom").get(0).offsetHeight - $("footer").get(0).offsetHeight - $("header").get(0).offsetHeight),
          ]
          ) + 'px'});
    }
    //also fix top "padding"
    if ($('#wpadminbar').length > 0 && $('#wpadminbar').css('position') != 'absolute') {
      $("#top").css({'top': $('#wpadminbar').get(0).offsetHeight + 'px'});
    }
  }

  //sidebar filler fix
  resizeSidebar();
  setInterval(resizeSidebar, 300);//bandaid for jetpack
  $(window).resize(resizeSidebar);



}); // END document ready